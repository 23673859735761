import React from 'react';
import Head from 'next/head';
import { LOGO_FAVICON_URL, LOGO_NAME, LOGO_URL } from '../constants';

function Seo({ title, desc }: { title: string, desc: string }) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta
        name="og:description"
        property="og:description"
        content={desc}
      />
      <meta property="og:site_name" content={LOGO_NAME} />
      <meta property="og:url" content="" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:site" content={LOGO_NAME} />
      <meta name="twitter:creator" content="" />
      <link
        rel="icon"
        type="image/x-icon"
        href={LOGO_FAVICON_URL}
      />
      <link rel="apple-touch-icon" href={LOGO_URL} />
      <meta property="og:image" content={LOGO_URL} />
      <meta name="twitter:image" content={LOGO_URL} />
      <link rel="canonical" href="" />

      {/* prevent search engines from indexing this page */}
      <meta name="robots" content="noindex" />
    </Head>
  );
}
export default Seo;
